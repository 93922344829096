<script setup>
import { useAppSetup } from "~/composables/useAppSetup";
import { useApp } from "~/composables/useApp";

const { htmlElements, sheets, dialogs, appVars } = useApp();
const { loadingApp } = useAppSetup();
</script>
<template>
  <ClientOnly>
    <v-splash-screen v-if="loadingApp"></v-splash-screen>
    <v-screen-tabs :ref="(el) => (htmlElements.appTabsEl = el)" class="page">
      <!-- home bible -->
      <v-screen-tab name="home">
        <v-screen-group :ref="(el) => (htmlElements.homeScreensEl = el)">
          <!-- home -->
          <v-screen name="bible">
            <the-header></the-header>
            <v-screen-single-bible></v-screen-single-bible>
          </v-screen>
          <v-screen name="parallel">
            <v-screen-parallel></v-screen-parallel>
          </v-screen>
          <v-screen name="parallel-select-bible">
            <v-screen-select-bible></v-screen-select-bible>
          </v-screen>

          <v-screen name="lessons">
            <v-screen-lessons></v-screen-lessons>
          </v-screen>

          <v-screen name="settings">
            <v-screen-user-settings></v-screen-user-settings>
          </v-screen>
          <v-screen name="history">
            <v-screen-history></v-screen-history>
          </v-screen>
        </v-screen-group>
      </v-screen-tab>

      <!-- search tab -->
      <v-screen-tab name="search">
        <v-screen-search></v-screen-search>
      </v-screen-tab>

      <!-- gallery tab -->
      <v-screen-tab name="gallery">
        <v-screen-gallery></v-screen-gallery>
      </v-screen-tab>

      <!-- today tab -->
      <v-screen-tab name="bibleplans">
        <v-screen-bible-plans></v-screen-bible-plans>
      </v-screen-tab>
    </v-screen-tabs>
    <!-- strong -->
    <v-strong-sheet></v-strong-sheet>

    <!-- plans -->
    <v-bible-plan-dialog
      v-model="dialogs.plans.dialog"
      :planModel="dialogs.plans.planModel"
    ></v-bible-plan-dialog>

    <v-share-sheet
      v-model="sheets.shareSheet.sheet"
      :shareText="sheets.shareSheet.text"
    ></v-share-sheet>

    <v-bible-plan-reset-dialog
      v-model="dialogs.planReset.dialog"
    ></v-bible-plan-reset-dialog>

    <v-bible-courses-dialog
      v-model="dialogs.bibleCourses.dialog"
    ></v-bible-courses-dialog>

    <the-main-menu v-model:dialog="appVars.appMenu"></the-main-menu>

    <the-footer></the-footer>
  </ClientOnly>
</template>
<style>
.page {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

  /* overflow-y: hi; */
  /* overflow-x: hidden; */
  /* height: 100%; */
}
.footer {
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
</style>
